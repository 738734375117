<template>
  <el-card class="box-card" :body-style="{padding: 0}">
    <iframe src="https://wu-jian-qin.gitee.io/class-photo/mobile/" frameborder="0" scrolling="0"></iframe>
  </el-card>
</template>
<script>
export default {
  data () {
    return {
    }
  }, methods: {
  }, mounted () {

  }
}
</script>
<style  scoped>
iframe {
  height: 100vh;
  width: 100vw;
}
.black {
  position: relative;
}
</style>